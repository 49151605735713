import Login from '@shared/features/login/Login';

const ChromeLogin = () => {
  return (
    <Login
      authBaseUrl={import.meta.env.VITE_API_BASE_URL}
      displayLogo={false}
      appName="Gmail"
    />
  );
};

export default ChromeLogin;
