import { Button, CircularProgress, Text, TextField } from '@shared/components';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const login = async (authBaseUrl: string, email: string, password: string) => {
  // Grandfathered in.
  // eslint-disable-next-line no-restricted-syntax
  return await fetch(`${authBaseUrl}/auth0/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
};

export interface LoginProps {
  authBaseUrl: string;
  displayLogo: boolean;
  appName: string;
  headerText?: string;
}
const Login = ({
  authBaseUrl,
  displayLogo,
  appName,
  headerText,
}: LoginProps) => {
  const { setToken } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const validate = () => {
    return password && email;
  };

  const handleLoginSuccess = (token: string) => {
    setToken(token);
    navigate(-1);
  };

  const handleLogin = async () => {
    if (!authBaseUrl) {
      setError('Auth base URL is not set. Check environment setup.');
      return;
    }

    if (validate()) {
      setIsLoading(true);
      setError('');
      const res = await login(authBaseUrl, email, password);
      setIsLoading(false);

      if (res.status === 429) {
        setError('Too many login attempts. Please try again later.');
        return;
      }

      const data = await res.json();

      if (!data.success) {
        setError('Invalid email or password.');
        return;
      }

      handleLoginSuccess(data.access_token);
    } else {
      setError('Please complete all required fields.');
    }
  };

  return (
    <div className="m-8 flex h-screen flex-col justify-start gap-6">
      {displayLogo && (
        <img className="self-center" width="128" src="logo.png" />
      )}
      <Text type="body-sm" className="text-gray-700">
        {headerText ??
          `Automate quoting and order entry with the Vooma add-in ${
            appName ? `for ${appName}` : ''
          }`}
      </Text>

      <div className="flex flex-col gap-3">
        <TextField
          value={email}
          onChange={(val) => setEmail(val)}
          label="Email"
          type="email"
          className="w-full"
          validationState={error && !email ? 'invalid' : undefined}
          isDisabled={isLoading}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleLogin();
          }}
        />
        <TextField
          value={password}
          onChange={(val) => setPassword(val)}
          label="Password"
          type="password"
          className="w-full"
          validationState={error && !password ? 'invalid' : undefined}
          isDisabled={isLoading}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleLogin();
          }}
        />
        <Button
          isDisabled={isLoading}
          size="medium"
          variant="primary"
          className="mt-2 w-full"
          onPress={handleLogin}
        >
          Log in
        </Button>
        {error && (
          <Text className="text-red-500" type="body-md">
            {error}
          </Text>
        )}
        {isLoading && (
          <div className="flex h-1/4 w-full items-center justify-center">
            <CircularProgress size="small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
